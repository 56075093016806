export const companyPhone = '010-8179-7941'
export const companyName = '민원인터넷발급'
export const companyEmail = 'e-minwon@naver.com'
export const companyCeo = '손미희'
export const companyNum = '690-46-01187'
export const companyAddr = '경기도 김포시 양촌읍 양곡로 495 3층 305-LV38호'
export const companyFax = '02-6280-7942'
export const companyMosan = '2024-경기김포-3285'
export const siteTitle = '민원인터넷발급'
export const billiard = '※'
export const termsOfService = `
제1장 총칙

제1조(목적) 이 약관은 민원인터넷발급(이하본사이트)이 제공하는 서비스의 이용 절차와 조건, 기타 필요한사항을 규정함을 목적으로 합니다.

제2조(약관 외 준칙) 이 약관에 명시되지 않은 사항은 전기통신기본법률, 전기통신사업법률 및 기타 관계 법률의 규정에 따릅니다.

제2장 회원 가입 및 서비스의 이용

제3조(이용 계약의 성립) 이용 계약은 회원 가입 희망자의 회원가입 신청에 대하여 민원인터넷발급(이하본사이트)가 승낙함으로써 성립합니다.

제4조(가입신청) 회원 가입 신청은 민원인터넷발급(이하본사이트)의 서비스 SITE의 On-line상에서 하며, 회원 가입 신청서에서 민원인터넷발급(이하본사이트)가 요청하는 사항을 성실하게 기재하여야 합니다.

제5조(회원의 구분) 회원의 구분은 다음과 같습니다.
소정의 양식에 따른 신청서와 약관에 동의하여 가입을 완료한 자를 회원으로 정의합니다.

제6조(회원 가입의 승낙)
회원의 가입은 회원 가입신청서를 작성하여 제출함으로써 승낙합니다.

제7조(회원 가입의 제한)
① 민원인터넷발급(이하본사이트)은 다음의 각호에 해당하는 경우에는 회원 가입을 제한할 수 있으며, 가입된 회원에 대한 회원 자격을 제한할 수 있습니다.
1.타인의 명의를 도용하여 허위의 회원 정보를 기재하여 회원에 가입하였음이 발견된 때
2.E-MAIL 주소, 연락 가능한 거주지 주소,전화 번호를 허위로 기재하여 민원인터넷발급(이하본사이트)의 의무를 이행할 수 없게 한 때
3.주민등록번호를 허위로 기재한 때
4.타인의 ID나 비밀번호를 도용하여 사용한 때
5.민원인터넷발급(이하본사이트)의 승인 없이 광고를 게재하거나 영업활동을 하는 때
6.민원인터넷발급(이하본사이트)의 서비스 제공에 해악을 끼치거나 사회 안녕질서 및 미풍양속을 저해할 목적 또는 범죄의 목적으로 회원에 가입하거나 행위를 한 때
② 제1항의 경우 민원인터넷발급(이하본사이트)은 그 사실을 회원이 제공한 연락처로 통지하고 해명할 기회를 주어야 한다.
③ 제2항에 의한 해명이 정당한 경우에는 민원인터넷발급(이하본사이트)은 지체 없이 회원의 자격 제한등을 해제하여야 합니다.

제8조(서비스의 이용)
① 서비스 이용은 연중무휴, 1일 24시간 동안 ON-LINE을 통해 이용할 수 있습니다.
② 민원인터넷발급(이하본사이트)은 다음 각호에 해당하는 때에는 서비스 제공을 일시적으로 중지할 수 있습니다.
1. 서비스 관련 설비의 보수 내지는 공사로 부득이 하게 서비스를 제공하기 심히 곤란한 때
2. 국가 비상 사태,천재지변, 정전, 서비스 설비의 장애, 서비스 이용의 폭주등으로 정상적인 서비스 제공이 심히 곤란한 때
3. 전기통신사업법률에 규정된 기간통신사업자가 전기 통신 서비스를 중지했을 때
4. 기타 불가항력적인 사유가 있는 때
③ 민원인터넷발급(이하본사이트)은 제2항의 사유에 의하여 서비스의 이용이 제한되거나 중지되는 때에는 그 사유 및 제한 시간 등을 지체없이 회원에게 알려야 한다.

제9조(회원의 의무와 책임)
회원은 사용자 ID,비밀번호를 민원인터넷발급(이하본사이트)의 승낙 없이 타인에게 양도 또는 대여할 수 없습니다. 그에 따른 회원의 불이익과 사용자 ID와 비밀번호의 관리소홀에 의한 부정사용에 의하여 발생한 모든 결과에 대한 책임은 회원에게 있습니다.

제10조(회원의 게시물)
① 회원 등은 다음의 각호에 해당하는 내용을 게시하거나 등록할 수 없습니다.
1. 정당한 이유 없이 민원인터넷발급(이하본사이트)을 비방하거나 명예를 손상시키는 내용
2. 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용
3. 법령 위반 및 사회 공공 질서, 건전한 미풍양속에 위반되는 내용
4. 민원인터넷발급(이하본사이트)나 제3자의 저작권을 침해하는 내용
5. 민원인터넷발급(이하본사이트)의 승인 없이 회원이 게시판에 광고등을 게재하는 내용
6. 바이러스를 유포시키는 게시 내용
② 제1항의 경우에는 민원인터넷발급(이하본사이트)은 게시물의 내용을 삭제할 수 있습니다.

제11조(광고)
서비스 이용자는 서비스 이용시 노출되는 광고 게재에 대하여 회원과 기타 이용자의 동의가 있었던 것으로 간주합니다.

제12조(서비스 이용책임)
① 민원인터넷발급(이하본사이트)이 승인하지 아니한 서비스를 이용하거나 영업 활동을 한 결과로 발생한 손실과 관련 법령의 위반에 대하여는 전적으로 그 이용자와 제공자의 책임 입니다.
② 민원인터넷발급(이하본사이트)이 승인하여 게재한 광고주의 판촉 활동에 회원이 참여하거나 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 민원인터넷발급(이하본사이트)은 책임지지 않습니다.

제13조(회원 정보 변경 및 해지)
① 회원이 회원 기간 중에 회원가입 정보를 변경하고자 하는 때에는 로그인 후에 정보수정란을 통해서 직접 해야 합니다.
② 회원 ID의 변경은 할 수 없습니다.
③ 민원인터넷발급(이하본사이트)이 가입 이전에 미리 해지할 수 없음을 공지한 경우에는 회원은 해지할 수 없습니다.
④ 회원탈퇴는 로그인 후에 정보수정란 하단 좌측버튼을 누름으로서 완료됩니다.
다만, 회원가입 후 24시간 이내는 탈퇴할 수 없습니다.

제3장 민원인터넷발급(이하본사이트)의 의무와 권리

제14조(의무)
① 민원인터넷발급(이하본사이트)은 안정적이고 지속적으로 양질의 서비스를 성실하게 제공하도록 노력하여야 한다.
② 민원인터넷발급(이하본사이트)은 회원의 개인정보를 보호하기 위하여 법령의 규정에 의한 경우를 제외하고는 회원의 서비스 이용과 관련하여 발생한 개인 정보를 본인의 승낙 없이 타인에게 누설 또는 배포하여서는 아니된다.
③ 민원인터넷발급(이하본사이트)은 회원의 정보를 성실하고 정직하게 본래의 서비스 목적으로만 사용하 여야 한다.
④ 민원인터넷발급(이하본사이트)은 회원의 서비스 이용에 제한을 두거나 회원에게 불리한 서비스의 변경이 있는 경우에는 이를 일정 기간 동안 공지하여야 한다.

제15조(민원인터넷발급(이하본사이트)의 면책)
① 민원인터넷발급(이하본사이트)은 회원이 사용할 수 있는 공간에서 회원 간에 의해 발생한 손해에 대해 책임지지 아니합니다.
② 민원인터넷발급(이하본사이트)은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
③ 민원인터넷발급(이하본사이트)은 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그밖에 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
④ 민원인터넷발급(이하본사이트)은 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.
⑤ 민원인터넷발급(이하본사이트)은 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.

제16조(손해배상 등)
① 다음의 각호에 해당하는 행위를 하는 때에는 민원인터넷발급(이하본사이트)은 손해배상을 청구할 수 있습니다.
1. 서비스 이용자가 고의 또는 과실로 민원인터넷발급(이하본사이트)의 서비스 이용을 방해하거나 명예를 손상한 때
2. 민원인터넷발급(이하본사이트)의 승인 없이 회원 ID나 비밀번호를 대여 또는 공개하는 때
3. 민원인터넷발급(이하본사이트)의 승인 없이 민원인터넷발급(이하본사이트)의 서비스나 SITE를 이용하여 광고를 게재하거나 영업활동을 하는 때
4. 기타 관련 법령에 위배되거나 민원인터넷발급(이하본사이트)의 이용 규정에 위반한 때

최종수정일 : 2024년5월1일
`

export const providingInfo = `
회사는 서비스신청, 상담, 회원가입 등을 위하여 아래와 같이 개인정보를 수집하고 있습니다.

 1) 개인정보의 수집이용목적 

   당사는 다음의 목적을 위하여 개인정보를 수집 및 이용합니다. 
   수집된 개인정보는 정해진 목적 이외의 용도로는 이용되지 않으며, 수집목적이 변경될 경우 사전에 알리고 동의를 받을 예정입니다.

 2) 수집하는 개인정보의 항목 
   회사는 서비스신청 고객상담 등을 위하여 아래와 같은 개인정보를 수집합니다. 
 - 필수항목: 성명, 휴대폰, 이메일, 비밀번호, 회원가입시 아이디, 
 - 선택항목: 일반전화번호, 팩스번호, 우편물발송주소, 환불시 계좌정보 
 -자동생성정보: 서비스이용기록접속로그, 접속아이피정보, 결제기록, 이용정지기록 등이 수집될 수 있습니다. 


 3) 개인정보 수집방법 
   회사는 다음과 같은 방법으로 개인정보를 수집합니다. 
 - 홈페이지 서면양식을 통한 신청서 
 - 전화, 팩스, 고객상담 게시판을 통한 서비스 이용관리 


 4) 개인정보의 처리목적 
 - 서비스제공에 관한 계약이행 및 요금정산, 서비스 및 콘덴츠제공 물품배송 또는 청구서 발송 등, 본인인증, 구매 및 
   요금결제, 요금추심. 


 5) 개인정보의 보유 및 파기 
   원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 파기합니다. 다만 다른법령에 따라 보존하여야 
   하는경우에는 그러하지 않을 수 있습니다. 이용자가 입력한 정보는 회원탈퇴 등 목적달성 후 내부방침 및 기타 관련 
   법령에 따라 저장된 후 파기됩니다. 
   ○ 회사 내부방침에 의한 정보보유 
   - 서비스이용의 혼선방지, 분쟁해결 및 수사기관의 요청에 따른 협조: 1년 
   ○ 관련 법령에 의한 정보보유 
   - 계약 또는 청약철회 등에 관한기록: 5년 
   - 대금결제 및 재화 등의 공급에 관한기록: 5년 
   - 소비자 불만 및 분쟁처리에 관한기록: 3년 
   - 로그기록: 통신비밀보호법: 3개월 


 6) 동의를 거부 할 권리 
   누구나 동의거부 의사를 표시할 수 있으며 동의 거부시 고객으로서 기본활동을 위한 정보부족으로 서비스 제공을 할 수 
   없으며, 이에 따라 회원가입 및 홈페이지를 이용한 민원서비스가 제한됩니다.
`

export const termsOfPrivate = `
(수집항목) 성명, 상호, 주소(사업장), 전화번호 (수집목적) 증명발급, 사후관리 등 (보유기간) 5년
위와 같이 개인정보 수집 및 이용한데 동의하십니까?
`
export const termsOfId = `
고유식별정보 수집 이용 내역(개인정보보호법 제24조)
(수집항목) 주민등록번호, 외국인등록번호, 운전면허번호, 여권번호 (수집목적) 증명발급, 사후관리 등 (보유기간) 5년
위와 같이 개인정보 수집 이용하는데 동의하십니까?
`
export const termsOf14 = `
신청인이 만 14세 미만 아동인 경우 위와 같이 개인 정보 및 고유식별정보를 처리하는데 동의하십니까?(개인정보보호법 제22조)
`
export const termsOfAttroney = `
위 납세자는 아래 위임받은 사람에게 위 민원의 신청 및 수령에 관한 권리와 의무를 위임합니다.
`
export const termsOfPunish = `
다른 사람의 인장 동용 등 허위로 위임장을 작성하여 신청할 경우에는 형법 제231조와 제232조에 따라 사문서 위변조 죄로 
5년 이하의 징역 또는 1천만원 이하의 벌금에 처하게 됩니다.
`

export const attrGuide = `
※ 위임자가 법인 경우에는 대표이사 등의 신분증(사본) 또는 법인인감증명서(사용인감 가능)(사본), 그 외의 경우에는 위임자 본인의 신분증(사본)(본이이 희망하는 경우에는 인감증명서, 본인서명사실확인서, 전자본인서명확인서 가능(사본포함))
※ 인감증명서 등(사본 포함)은 3개월 이내에 발급된 것이어야 합니다.
`

export const prss = {
  am: `당일 PM 14~16시`,
  pm: `익일 PM 14~16시`,
  // pm: `익일 AM 10~12시(영업일)`,
  short: `10~30분`,
  long: `10분~4시간`,
}

export const refund = `
·서류처리 순서는 접수 처리중 처리완료 단계로 진행됩니다.
·취소 및 환급은 접수 단계에서만 가능하며, 일처리가 진행된 처리중 이후는 취소및 환급되지 않습니다.
·고객님이 작성한 신청서 기재오류로 처리불가 시에는 취소 및 환급되지 않으며, 처리불가 된 내역은 수정하여 주시면 1회에 한하여 재발급 진행됩니다.
·내용을 확인하신 후 타당하다고 판단하시면 반드시 고객센터로 신청바랍니다. 고객센터로 신청하지 않은 건은 환급(취소)되지 않습니다.
`

export const refund2=`
무통장결제후 취소요청시 반드시 010-8179-7941 으로 신청인 환불계좌를 문자 발송주여야합니다. 미발송시 환불불가합니다.
`
