import * as React from 'react'

import { companyPhone, 
  companyName, companyCeo, companyEmail, companyNum, companyAddr, companyMosan,
  termsOfService,
  providingInfo,
} from '@util/string'
import {
  Grid,
  Typography,
} from '@mui/material'
import MyDialog2 from '@components/MyDialog2'
const textStyle = {
  color:'#fff', 
  fontSize:'0.9em'
}

const TermsOfService = () => {
  return (
    <Typography style={{whiteSpace:'pre-wrap'}}>
      {termsOfService}
    </Typography>
  )
}

const PrivateInfo = () => {
  return (
    <Typography style={{whiteSpace:'pre-wrap'}}>
      {providingInfo}
    </Typography>
  )
}
const Footer = () => {
  const dgRef = React.useRef(null)  
  const dgRef2 = React.useRef(null)  
  return (
    <>
      <MyDialog2 title={'이용약관'} ref={dgRef} component={<TermsOfService />} windowWidth='md' />
      <MyDialog2 title={'개인정보처리방침'} ref={dgRef2} component={<PrivateInfo />} windowWidth='md' />
      <Grid container spacing={2} style={{ 
        backgroundColor: '#616161', bottom:0, display:'flex', alignItems:'center',
        paddingLeft:100,
        paddingRight:100,
        paddingBottom:10,
      }} >
        <Grid item xs={12} md={4} style={{}} >
          <Typography 
            style={{...textStyle, cursor:'pointer'}}
            onClick = {() => dgRef.current.open()}
          >
            이용약관
          </Typography>
          <Typography 
            style={{...textStyle, cursor:'pointer'}}
            onClick = {() => dgRef2.current.open()}
          >
            개인정보처리방침
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography style={textStyle}>회사명:{companyName}</Typography>
          <Typography style={textStyle}>사업자등록번호:{companyNum}</Typography>
          <Typography style={textStyle}>대표:{companyCeo}</Typography>
          <Typography style={textStyle}>통신판매업:{companyMosan}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography style={textStyle}>주소:{companyAddr}</Typography>
          <Typography style={textStyle}>고객센터:{companyEmail}</Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Footer
